import styled, { css } from 'styled-components'
import {
  mq,
  colors,
  spacing,
  typography,
} from '../../../styles/utilities/variables'
import StyledContent from '../../Content/style'
import StyledText from '../../Text/style'
import StyledHeading from '../../Heading/style'
import StyledForm from '../../Form/style'
import StyledButton from '../../Button/style'
import StyledWrapper from '../../Wrapper/style'

export const StyledCalculator = styled.div`
  z-index: 2;
  grid-row: 1;
  grid-column: 2/8;
  display: flex;
  flex-direction: column;
  align-self: center;
  @media ${mq.desktopLarge} {
    grid-column: 2/7;
  }
  @media ${mq.tablet} {
 //   grid-column: 2/14;
    grid-row: 1/3;
    align-self: flex-start;

  }
  @media ${mq.mobile} {
  align-self: flex-start;
  }

  @media ${mq.tablet} {
    margin-top: ${spacing.xxxLarge};
    }
 
  ${StyledContent} {
    max-width: 55.6rem;
    
    ${StyledHeading.h3} {
      margin-bottom: ${spacing.base};
    }

    @media ${mq.tablet} {
      max-width: unset;
    }

    @media ${mq.mobile} { 
      ${StyledHeading.h3} { 
        font-size: ${typography.sizes.medium};
     }

      ${StyledText} { 
        font-size: ${typography.sizes.xSmall};
      }
    
    }

  }


  ${StyledForm.Input} {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.lightYellow};
    max-width: unset;
    font-size: ${typography.sizes.xxLarge};
    font-family: ${typography.MatrisePro};
    color: ${colors.brightYellow};
    padding: ${spacing.base} 0;
    border-radius: 0px;
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        border-bottom: 1px solid ${colors.pink};
        color: ${colors.pink};
      `}
    @media ${mq.mobile} { 
     font-size: ${typography.sizes.large};
     padding: ${spacing.xSmall} 0;
     }
    
  }
  ${StyledForm.Label} {
    color: ${colors.lightYellow};
    padding: ${spacing.small} 0 ;
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        color: ${colors.white};
      `}
  }

  ${StyledButton} {
    width: 16rem;
    position: absolute;
    right: 0;
    z-index: 2;

    @media ${mq.mobile} { 
         width: fit-content;
         padding: ${spacing.small} ${spacing.base};
     }
  }
  ${StyledWrapper} {
    position: relative;
  //  margin-top: ${spacing.large};
    padding: 0;
    ${StyledForm.Input} {
      padding-right: 18rem;
      @media ${mq.mobile} { 
        padding-right: 12rem;
     }
    }

 
  }
`

export const StyledCalculatorResponse = styled.div`
  z-index: 2;
  grid-column: 9/12;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: ${spacing.xSmall};
  font-weight: 500;
  line-height: 1.1;

  ${StyledWrapper} {
    max-width: 35.7rem;
    padding: 0;
    line-height: 1.5;
    margin-top: ${spacing.small};
  }

  @media ${mq.tablet} {
    grid-column: 2/12;
    grid-row: 3;
    align-self: start;
  }
  @media ${mq.mobile} {
    ${StyledText} {
      font-size: ${typography.sizes.xSmall};
    }
  }
`

export const StyledCalculatorResponseText = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: end;

  span {
    font-family: ${typography.MatrisePro};
    font-size: ${typography.sizes.xxLarge};
    color: ${colors.brightYellow};
    margin-right: ${spacing.small};
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        color: ${colors.pink};
      `}
    @media ${mq.mobile} {
      font-size: ${typography.sizes.large};
    }
  }
  ${StyledText} {
    font-size: ${typography.sizes.large};
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 0.8rem;
    }
    @media ${mq.tablet} {
      &:last-child {
        margin-bottom: 0.6rem;
      }
    }
    @media ${mq.mobile} {
      font-size: ${typography.sizes.medium};
      &:last-child {
        margin-bottom: 0.2rem;
      }
    }
  }
`
