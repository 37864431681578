import styled, { css } from 'styled-components'
import {
  colors,
  maxWidths,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'
import StyledWrapper from '../Wrapper/style'
import StyledHeading from '../Heading/style'
import StyledText from '../Text/style'

export const StyledCalculatorContainer = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-x: ${props => (props.overflow ? 'hidden' : '')};

  background: linear-gradient(#ffffff 34%, #133024 33%, #133024 33%);
  ${props =>
    props.themeColor === 'blueTheme' &&
    css`
      background: linear-gradient(#ffffff 34%, #011726 33%, #011726 33%);
    `}
  @media ${mq.tablet} {
    background: linear-gradient(#ffffff 20%, #133024 10%, #133024 80%);
    ${props =>
    props.themeColor === 'blueTheme' &&
    css`
      background: linear-gradient(#ffffff 20%, #011726 10%, #011726 80%);
    `}
    ${StyledWrapper} {
      row-gap: ${spacing.medium};
    }
  }
`

export const StyledCalculatorGrid = styled.div`
  width: 100%;
  max-width: 1660px;
  min-height: 50rem;
  height: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 8rem 0;
  margin: 0 auto;
  row-gap: ${spacing.xxLarge};

  ${StyledWrapper} {
    grid-column: 2/12;
  }

  @media ${mq.tablet} {
    display: flex;
    flex-direction: column;
    padding: 8rem 5.7rem;
    gap: ${spacing.xlarge};
  }
  @media ${mq.mobile} {
    gap: ${spacing.large};
    padding: 8rem ${spacing.medium};
    ${StyledWrapper} {
      // flex-direction: column;
      align-self: flex-start;
      width: 100%;
    }
  }
`

export const StyledRevenueHeading = styled.div`
  grid-column: 2/12;
  z-index: 2;
  @media ${mq.tablet} {
   //grid-column: 2/14;
    //padding: ${spacing.medium};
  }
  @media ${mq.mobile} {
    margin-top: 14rem;
    padding: 0;
    ${StyledHeading.h4} {
      font-size: ${typography.sizes.medium};
    }
    ${StyledText} {
      font-size: ${typography.sizes.small};
    }
  }
`

export const StyledCalculatorCircle = styled.div`
  grid-row: 1;
  aspect-ratio: 1/1;
  max-width: ${props => (props.width ? `${props.width}rem` : '')};
  background-color: ${props =>
    props.color ? colors[props.color] : colors.forestGreen};
  border-radius: 50%;
  margin-bottom: 2rem;
  /* @media ${mq.smallTablet} {
    position: absolute;
    top: 0;
  } */
  &:first-of-type {

    grid-column: 1/10;
    z-index: 1;
    transform: translateX(-100px);

    max-width: 77.5rem;
    width: 90vw;

    @media (min-width: 1200px){
      max-width: 99.5rem;
    }

    @media ${mq.desktopxLarge} {

      transform: translateX(0px);
    }
 
    @media ${mq.tablet} {
      min-width: 43.5rem;
      max-width: 80rem;
      width: 80vw;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-160px);
    }

  }
  &:nth-of-type(2) {
    grid-column: 5/13;
    transform: translateX(100px);
    margin-top: ${spacing.xxLarge};
    position: unset;
    @media ${mq.desktopxLarge} {
      grid-column: 6/13;
      grid-row: 1/3;
      transform: translateX(0px);
    }
 
    @media ${mq.tablet} {
      min-width: 66.5rem;
      max-width: 90rem;
      width: 130vw;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-40px);
    }
    @media ${mq.desktopMin} {
      right: 0;
      left: unset;
      transform: translateX(100px);
    }
  }
`
