import React, { useRef, useState, useEffect } from 'react'
import {
  StyledCalculator,
  StyledCalculatorResponse,
  StyledCalculatorResponseText,
} from './style'
import { Content } from '../../Content'
import { Heading } from '../../Heading'
import { Form } from '../../Form'
import { Text } from '../../Text'
import { Wrapper } from '../../Wrapper'
import { Button } from '../../Button'
import { useForm } from 'react-hook-form'

const CalculatorForm = ({ currency, revenues, themeColor }) => {
  const [packages, setPackages] = useState(18)
  const [allProfits, setTotalProfit] = useState([])

  const sellersRef = useRef()
  const goalRef = useRef()

  //let averageProfit

  /** Set sum of all profits of packages */
  // useEffect(() => {
  //   revenues.map(revenue => {
  //     setTotalProfit(allProfits => [...allProfits, revenue.profit])
  //   })
  // }, [])

  // const calculateAverageProfit = () => {
  //   const totalSum = allProfits.reduce(
  //     (previousValue, currentValue) => previousValue + currentValue,
  //     0
  //   )
  //   averageProfit = totalSum / allProfits.length

  //   return averageProfit
  // }

  /** Form */
  const { handleSubmit, register } = useForm()

  const onSubmit = data => {
    // calculateAverageProfit()
    //formula  x = y/z
    //x = the number of units need to be sold
    //y = the profit goal
    // z = the profit per sale
    let averageProfit = 47

    if (data.goal && data.sellers) {
      const units = data.goal / averageProfit
      const sellerPerPackage = Math.round(units / data.sellers)
      setPackages(sellerPerPackage)
    } else {
      setPackages(0)
    }
  }
  const headingColor = themeColor === 'blueTheme' ? 'pink' : 'brightYellow'
  const textColor = themeColor === 'blueTheme' ? 'white' : 'lightYellow'
  return (
    <>
      <StyledCalculator themeColor={themeColor}>
        <Content>
          <Heading
            type='h3'
            size={'_72'}
            color={headingColor}
            lineHeight={'1.1'}
          >
            Hur mycket vill ni tjäna?
          </Heading>
          <Text color={textColor}>
            Här kan du lätt beräkna hur mycket ni behöver sälja för att nå
            målet. Det är bara att mata in siffrorna.
          </Text>
        </Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Input
            name='sellers'
            type='number'
            defaultValue={20}
            innerRef={register({ sellersRef })}
          />
          <Form.Label for='sellers'>Antal säljare</Form.Label>
          <Wrapper align={'center'}>
            <Form.Input
              name='goal'
              type='number'
              defaultValue={16500}
              innerRef={register({ goalRef })}
            />
            <Button
              themeColor={
                themeColor === 'blueTheme' ? 'pinkTheme' : 'greenTheme'
              }
            >
              BERÄKNA
            </Button>
          </Wrapper>
          <Form.Label for='goal'>Försäljningsmål i kronor</Form.Label>
        </Form>
      </StyledCalculator>
      <StyledCalculatorResponse>
        <StyledCalculatorResponseText themeColor={themeColor}>
          <Text color={textColor}>
            <span>{packages}</span>
          </Text>
          <Text color={textColor}>paket/person</Text>
        </StyledCalculatorResponseText>

        <Wrapper>
          <Text color={textColor}>
            För att nå ert försäljningsmål behöver varje säljare i genomsnitt
            sälja {packages} paket
          </Text>
        </Wrapper>
      </StyledCalculatorResponse>
    </>
  )
}

export { CalculatorForm }
