import styled, { css } from 'styled-components'
import {
  colors,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'
import StyledSplash from '../Splash/style'
import StyledColumns from '../../blocks/Columns/style'
import StyledImageFigure from '../ImageFigure/style'
import StyledImage from '../Image/style'
import StyledAlignWrapper from '../AlignWrapper/style'
import StyledContent from '../Content/style'
import StyledButton from '../Button/style'

const MobileComponentFlexStyle = () => css`
  @media ${mq.tablet} {
    align-items: flex-start;
  }
`

const ColumnMobileStyle = () => css`
  padding: 0 ${spacing.large};
  align-items: flex-start;
  h4,
  p {
    /* text-align: left; */
  }
  a {
    /* display: block; */
    font-weight: bold;
  }
`

const horizontalImage = () => css`
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  min-height: 43rem;
  @media ${mq.tablet} {
    min-height: 0;
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const StyledMediaTextShortcut = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: '${props => props.order}';
    // align-items: ${props => (props.vertical ? 'center' : 'start')};
    align-items: center;
    justify-content: start;
    position: relative;

    .no-cssgrid & {
      display:flex;
      flex-wrap:wrap;
    }

    & > * {
      .no-cssgrid & {
        width: 50%;
      }
    }

    ${StyledSplash} {
        width: 140px;
        position: absolute;
        top: 0;
        left: ${props => (props.imageFirst ? '-3%' : 'auto')};
        right: ${props => (props.imageFirst ? 'auto' : '-3%')};
        @media ${mq.tablet} {
          display: none;
        }
    }

    @media ${mq.tablet} {
        grid-template-areas: none;
        display: block;

        /* NO COLUMN STYLE */
        ${StyledImageFigure} {
            width: auto;
            height: 500px;
        }
    }

    ${StyledContent} + ${StyledAlignWrapper} > ${StyledButton.a}{
      margin-top: ${spacing.large};
    }

`

StyledMediaTextShortcut.Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${props => (props.imageFirst ? 'column' : 'column-reverse')};
  ${MobileComponentFlexStyle}
  @media ${mq.tablet} {
    flex-direction: column;
  }
`

StyledMediaTextShortcut.ImageContainer = styled.div`
  overflow: ${props => (props.horizontal ? 'visible' : 'hidden')};
  height: 100%;
  position: relative;
  grid-area: image;
  // aspect-ratio: ${props => (props.vertical ? '4/5' : '')}; // @TODO - this is not working

  video {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  
  ${StyledImage} {
    /* height: 480px; */
    position: absolute;
    z-index: 0;
    top: 0px;
    width: 100%;
    min-height: 100%;
    height: 100%;

    .no-cssgrid & {
      position:relative;
    }

    
    picture {
      height:100%;
    }
    img {
      height:100%;
      object-fit:cover;
      ${props =>
        props.objectPosition &&
        css`
          object-position: ${props => props.objectPosition};
        `}
    }
  }
  ${props =>
    props.horizontal &&
    css`
      ${StyledImage} {
        ${horizontalImage()}
      }
    `}

    @media ${mq.tablet} {
      width: 100%;
      margin-bottom: ${spacing.medium};

      ${StyledImage} {
        ${horizontalImage()}
      }
      ${props =>
        props.vertical &&
        css`
          aspect-ratio: unset;
          figure {
            height: 100%;
          }
        `}
  }

`

StyledMediaTextShortcut.TextContainer = styled.div`
  padding: ${props =>
    props.horizontal
      ? `${spacing.xLarge}`
      : `${spacing.xxxLarge} ${spacing.xxLarge}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-area: text;
  > div {
    width: 100%;
  }

  position: ${props => (props.horizontal ? 'initial' : 'relative')};
  ${props =>
    props.horizontal &&
    css`
      & ${StyledSplash} {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(7%, -25%) rotate(10deg);
        background: ${colors.primary};
        color: ${colors.white};
        width: 100px;
        height: 100px;
        font-size: ${typography.sizes.xSmall};

        @media ${mq.tablet} {
          display: none;
        }
      }
    `}

  ${StyledColumns}[cols="3"] & {
    padding: ${spacing.large} ${spacing.base};
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media ${mq.desktop} {
    padding: ${props =>
      props.horizontal
        ? `${spacing.xLarge}`
        : `${spacing.xxLarge} ${spacing.xLarge}`};
  }

  @media ${mq.tablet} {
    width: 100%;

    padding: ${spacing.xLarge};
    /* COLUMN STYLES */
    ${StyledColumns} [cols="3"] & {
      ${ColumnMobileStyle}
    }
    ${StyledColumns} [cols="2"] & {
      ${ColumnMobileStyle}
    }
  }

  @media ${mq.mobile} {
    padding: ${props => (props.vertical ? '0' : `${spacing.large} 0 0`)};
  }
`
export default StyledMediaTextShortcut
